import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { AdminService } from '../../../@domain/admin/application/admin.service';
import { MetaService } from '../../../@domain/seo/meta.service';
import { AdminRoute } from '../../../routing/types/admin-route';
import { BreadcrumbsComponent } from '../../admin/breadcrumbs/breadcrumbs.component';
import { HeaderComponent } from '../header/header.component';

@Component({
    selector: 'app-admin-layout',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        HeaderComponent,
        RouterOutlet,
        RouterLink,
        RouterLinkActive,
        ConfirmDialog,
        BreadcrumbsComponent,
        FaIconComponent
    ],
    styles: `
        ::ng-deep {
            @import "../../../../styles/admin/admin";
        }

        :host {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100dvh;
        }

        main {
            padding-top: 4.75rem;
            min-height: 100dvh;
            display: flex;
            overflow: hidden;
        }

        .sidebar {
            display: flex;
            flex-direction: column;
            gap: 0.25rem; /* Space between items */
            padding: 0.5rem;
            border: 0.5px solid black;
            margin-left: 1.25rem; /* Space between sidebar and adjacent content */
            width: fit-content; /* Sidebar wraps around its contents */
            height: fit-content;
        }

        .sidebar-item {
            justify-content: space-between;
            display: flex;
            align-items: center;
            padding: 0.25rem 0.75rem;
        }

        .sidebar-link {
            text-decoration: none;
            color: #64748b;
            font-weight: 600;
            font-size: 0.75rem;
            color: black;
            flex-grow: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        /* Ensures the selected item has a different font color */
        .sidebar-item.active .sidebar-link {
            color: #F63737;
        }

        .last-item {
            margin-top: 5rem; /* Adds space before the last item */
            justify-content: flex-start; /* Ensures no "+" sign alignment issues */
        }

        .content {
            flex: 1;
            padding: 1rem 2rem 5rem;
            overflow-y: auto;
        }

        .header {
            font-size: 1.2rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }

        app-breadcrumbs {
            margin-bottom: 0.5rem;
        }
    `,
    template: `
        <app-header/>
        <main>
            <div class="sidebar">
                @for (item of sidebarItems; track item.title) {
                    <div
                        class="sidebar-item"
                        [class.last-item]="sidebarItems.indexOf(item) === sidebarItems.length - 1"
                        [routerLinkActive]="'active'"
                    >
                        <a [routerLink]="item.route" routerLinkActive="active" class="sidebar-link">
                            {{ item.title }}
                        </a>
                    </div>
                }
            </div>
            <div class="content">
                <app-breadcrumbs [items]="breadcrumbs()"/>
                <router-outlet/>
            </div>
        </main>
        <p-confirmDialog/>
    `
})
export class AdminLayout {
    private adminService = inject(AdminService);
    private metaService = inject(MetaService);

    public breadcrumbs = this.adminService.getBreadcrumbs();
    public sidebarItems = [
        { title: 'EVENTS', route: AdminRoute.Events },
        { title: 'VIDEOS', route: AdminRoute.Videos },
        { title: 'AUTHORS', route: AdminRoute.Authors },
        { title: 'CATEGORIES', route: AdminRoute.Categories },
        { title: 'USERS', route: AdminRoute.Users },
        { title: 'ORGANIZATIONS', route: AdminRoute.Organizations},
        { title: 'INCOMING', route: AdminRoute.Incoming }
    ] as const;

    constructor() {
        this.metaService.set({
            title: 'Videolectures - Admin',
            description: undefined,
            image: undefined,
            index: false
        })
    }
}
